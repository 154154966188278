import Separator from "@/shared/Separator";
import { ParentGrid, Card } from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
const Container1PisoB = (props) => {
  const {
    link,
    sectionClass = "",
    title,
    titleEnabled = true,
    marfeelEnabled = true,
    title1,
    link1,
    title2,
    link2,
    title3,
    link3,
    title4,
    link4,
    titleTarget = "_self",
    linkImage,
  } = props;

  const marfeelTitle = title
    ? "container1PisoB-" +
      title
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    : "container1PisoB";

  const titlesExtras = [];
  if (title1 && link1) titlesExtras.push({ title: title1, link: link1 });
  if (title2 && link2) titlesExtras.push({ title: title2, link: link2 });
  if (title3 && link3) titlesExtras.push({ title: title3, link: link3 });
  if (title4 && link4) titlesExtras.push({ title: title4, link: link4 });

  return (
    props.content?.length > 0 && (
      <>
        {title && titleEnabled && (
          <Separator
            title={title}
            link={link}
            sectionClass={sectionClass}
            extras={titlesExtras}
            target={titleTarget}
            linkImage={linkImage}
          />
        )}
        <GridStyleColor>
          <ParentGrid
            {...(marfeelEnabled
              ? { "data-mrf-recirculation": marfeelTitle }
              : "")}
            className={`container1PisoB module-grid ${sectionClass}`}
          >
            <Card className="div1">
              {props.renderContainerWidgetCallback(
                1,
                props.positionProperties?.[0]?.templateNews ?? "TwoxOneNoImage",
                {
                  containerName: "contenedor_1_piso_tipo_b",
                }
              )}
            </Card>
            <Card className="div2">
              {props.renderContainerWidgetCallback(
                2,
                props.positionProperties?.[1]?.templateNews ?? "OnexOne",
                {
                  containerName: "contenedor_1_piso_tipo_b",
                }
              )}
            </Card>
            <Card className="halfParent">
              <div className="div3">
                {props.renderContainerWidgetCallback(
                  3,
                  props.positionProperties?.[2]?.templateNews ?? "Half",
                  {
                    containerName: "contenedor_1_piso_tipo_b",
                  }
                )}
              </div>
              <div className="div4">
                {props.renderContainerWidgetCallback(
                  4,
                  props.positionProperties?.[3]?.templateNews ?? "Half",
                  {
                    containerName: "contenedor_1_piso_tipo_b",
                  }
                )}
              </div>
            </Card>
          </ParentGrid>
        </GridStyleColor>
      </>
    )
  );
};
export default Container1PisoB;
